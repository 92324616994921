var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"error-box error-box--block\" data-i18n=\"[html]sa.too_many_exams_warning\">\n  "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.too_many_exams_warning",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":2},"end":{"line":19,"column":35}}}))
    + "\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"deletionDate") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":33,"column":6},"end":{"line":37,"column":13}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./deleted-exams-table-row.hbs"),depth0,{"name":"../templates/deleted-exams-table-row","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(require("./available-exams-table-row.hbs"),depth0,{"name":"../templates/available-exams-table-row","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h1 data-i18n=\"sa.exams_tab\" class=\"exam-list-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exams_tab",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":53},"end":{"line":1,"column":73}}}))
    + "</h1>\n"
    + ((stack1 = container.invokePartial(require("./partials/instructions_wrapper.hbs"),depth0,{"name":"instructions_wrapper","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<div class=\"exam-buttons-wrapper\">\n    <div>\n        <div>\n            <button id=\"js-add-xml-exam-event\" class=\"big-sa-button bertta-button\" data-i18n=\"sa.create_exam_mex\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.create_exam_mex",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":114},"end":{"line":8,"column":42}}}))
    + "</button>\n"
    + ((stack1 = container.invokePartial(require("./partials/bertta_instructions_wrapper.hbs"),depth0,{"name":"bertta_instructions_wrapper","fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n    <div id=\"exam-import-container\"></div>\n</div>\n<h3><span data-i18n=\"sa.exams\" class=\"exam-table-title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exams",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":56},"end":{"line":15,"column":72}}}))
    + "</span> (<span class=\"js-exam-total\"></span>)</h3>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooManyExams") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":21,"column":7}}})) != null ? stack1 : "")
    + "<input type=\"checkbox\" id=\"show-deleted-exam\" class=\"show-deleted-input\"/> <label for=\"show-deleted-exam\" class=\"show-deleted-label\" data-i18n=\"sa.show_deleted\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.show_deleted",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":161},"end":{"line":22,"column":184}}}))
    + "</label>\n<table id=\"available-exams\">\n  <thead>\n  <th data-i18n=\"sa.date\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":26},"end":{"line":25,"column":41}}}))
    + "</th>\n  <th data-i18n=\"sa.held_exam_name\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.held_exam_name",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":36},"end":{"line":26,"column":61}}}))
    + "</th>\n  <th data-i18n=\"sa.giving_exam\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.giving_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":33},"end":{"line":27,"column":55}}}))
    + "</th>\n  <th data-i18n=\"[html]sa.reusing_exam\">"
    + ((stack1 = lookupProperty(helpers,"t").call(alias1,"sa.reusing_exam",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":40},"end":{"line":28,"column":65}}})) != null ? stack1 : "")
    + "</th>\n  <th></th>\n  </thead>\n  <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"exams") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":32,"column":2},"end":{"line":38,"column":11}}})) != null ? stack1 : "")
    + "  </tbody>\n</table>\n";
},"usePartial":true,"useData":true});