import { RecursivePartial } from '../js/types'
import { fi } from './fi'

export const fiOverrides: RecursivePartial<typeof fi> = {
  sa: {
    app_name: 'Abitti',
    create_old_exam_description:
      "Vanhalla editorilla laadittua koetta ei voi ladata koetilan palvelimelle. <a target='_blank' href='https://www.abitti.fi/blogi/2022/09/abitin-syksy-vanha-koeformaatti-poistuu-ja-tikut-paivittyvat/'>Lue lisää</a>",
    old_editor_warning:
      "Tämä editori tulee poistumaan käytöstä 1.6.2023.<br/> Et voi ladata tällä editorilla tehtyjä kokeita koetilan palvelimelle, mutta voit kopioda kokeen Bertta-kokeeksi. <a target='_blank' href='https://www.abitti.fi/blogi/2022/09/abitin-syksy-vanha-koeformaatti-poistuu-ja-tikut-paivittyvat/'>Lue lisää</a>",
    bertta_instruction_url: 'https://www.abitti.fi/fi/ohjeet/kokeen-laatiminen/',
    exam_execution_instructions_url: 'https://www.abitti.fi/fi/opettajat/',
    exam_execution_instructions_help:
      "Tarvittaessa saat vinkkejä ja apua Abitti-tukitiimiltä: <a href='mailto:abitti@ylioppilastutkinto.fi'>abitti@ylioppilastutkinto.fi</a>, <a href='tel:+358295338280'>+358 295 338 280</a> (ark. 9-15)",
    too_many_exams_warning:
      'Sinulla on Abitissa yli 400 koetta. Poistamalla ylimääräisiä kokeita nopeutat koelistauksen latautumista.<br>Vanhimmat kokeet näkyvät koelistauksen lopussa.',
    errors: {
      grading_started: 'Tiedostossa on Abitissa jo olevia koesuorituksia.'
    }
  },
  lapa: {
    title: 'Abitti',
    exam_not_editable:
      'Tätä koetta ei voi muokata Abitissa. Voit tallentaa kokeen tietokoneellesi edellisen näytön "siirrä koe (.zip)" -painikkeella. Tarkemmat ohjeet löytyy <a target="_blank" href="http://www.abitti.fi/blogi/2015/11/kokeen-siirtaminen-ja-tuominen/">Abitti-blogista</a>.',
    xml_exam_not_editable: 'Tätä koetta ei voi muokata Abitissa.'
  },
  arpa: {
    application_name: 'Abitti'
  },
  registration: {
    welcome: 'Tervetuloa Oma Abitti -palveluun!'
  },
  footer: {
    contact_details: 'Abitti-tuki',
    meb: 'Ylioppilastutkintolautakunta',
    updates: 'Abitti-päivitykset',
    digabi_terms: 'Käyttöehdot ja tietosuojaseloste',
    copy: '© Abitti on Ylioppilastutkintolautakunnan rekisteröimä EU-tavaramerkki (015833742, 015838915).',
    office_hours: '(ark. 9-15)'
  },
  oauth: {
    consent:
      '<p>Haluatko myöntää verkkopalvelulle <strong>"{{clientName}}"</strong> pääsyn Abittiin tunnuksellesi (<strong>{{username}}</strong>)?</p>',
    scopes: {
      'exam:write': 'Verkkopalvelu voi lisätä Abitti-tilillesi kokeita.'
    }
  }
}
