import { fi } from './fi'
import { RecursivePartial } from '../js/types'

export const svOverrides: RecursivePartial<typeof fi> = {
  sa: {
    app_name: 'Abitti',
    create_old_exam_description:
      "Det går inte att ladda upp prov som skapats med den gamla editorn till provlokalens server. <a target='_blank' href='https://www.abitti.fi/blogi/2022/09/abitti-denna-host-det-gamla-provformatet-tas-ur-bruk-och-pinnarna-uppdateras/'>Läs mer</a>",
    old_editor_warning:
      "Denna editor kommer att tas ur drift den 1.6.2023.<br/> Du kan inte ladda upp prov som skapats med den till provlokalens server. Du kan ändå kopiera proven till Bertta-prov. <a target='_blank' href='https://www.abitti.fi/blogi/2022/09/abitti-denna-host-det-gamla-provformatet-tas-ur-bruk-och-pinnarna-uppdateras/'>Läs mer</a>",
    bertta_instruction_url: 'https://www.abitti.fi/sv/anvisningar/skapa-prov/',
    exam_execution_instructions_url: 'https://www.abitti.fi/sv/larare/',
    exam_execution_instructions_help:
      "Vid behov får du tips och hjälp av Abitti-stödteamet: <a href='mailto:abitti@ylioppilastutkinto.fi'>abitti@ylioppilastutkinto.fi</a>, <a href='tel:+358295338280'>+358 295 338 280</a> (vard. 9-15)",
    too_many_exams_warning:
      'Du har över 400 prov i Abitti. Du kan försnabba laddningen av provlistan genom att radera överflödiga prov.<br>De äldsta proven finns i slutet av provlistan.'
  },
  lapa: {
    title: 'Abitti',
    exam_not_editable:
      'Detta prov kan inte redigeras i Abitti. Du kan spara provet på din dator med förra vyns "Exportera provet (.zip)" knapp. Det finns mera instruktioner på <a target="_blank" href="http://www.abitti.fi/blogi/2015/11/exportera-och-for-in-provet/">Abitti-bloggen</a>.',
    xml_exam_not_editable: 'Detta prov kan inte redigeras i Abitti.'
  },
  arpa: {
    application_name: 'Abitti'
  },
  registration: {
    welcome: 'Välkommen till Mitt Abitti -tjänsten!'
  },
  footer: {
    contact_details: 'Abitti-stöd',
    meb: 'Studentexamensnämnden',
    updates: 'Abitti-uppdateringar',
    digabi_terms: 'Licensavtal och dataskyddsbeskrivning',
    copy: '© Abitti är ett EU-varumärke registrerat av Studentexamensnämnden (015833742, 015838915).',
    office_hours: '(vard. 9-15)'
  },
  oauth: {
    consent:
      '<p>Vill du ge webbtjänsten <strong>{{clientName}}</strong> tillgång till ditt Abitti-konto (<strong>{{username}}</strong>)?</p>',
    scopes: {
      'exam:write': 'Webbtjänsten kan lägga till prov på ditt Abitti-konto'
    }
  }
}
