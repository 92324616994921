var Handlebars = require("../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <p data-i18n=\"sa.settings.update_username.status.ready\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.settings.update_username.status.ready",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":58},"end":{"line":28,"column":108}}}))
    + "</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <p data-i18n=\"sa.settings.update_username.status.done\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"sa.settings.update_username.status.done",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":30,"column":57},"end":{"line":30,"column":106}}}))
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 data-i18n=\"lapa.exam_language\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"lapa.exam_language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":35},"end":{"line":1,"column":63}}}))
    + "</h2>\n\n<div class=\"form-group default-radio\">\n    <label><input type=\"radio\" name=\"default_language\" value=\"fi-FI\"><span class=\"radio-label\" data-i18n=\"lapa.exam_languages.fi-FI\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"lapa.exam_languages.fi-FI",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":133},"end":{"line":4,"column":167}}}))
    + "</span></label>\n    <label><input type=\"radio\" name=\"default_language\" value=\"sv-FI\"><span class=\"radio-label\" data-i18n=\"lapa.exam_languages.sv-FI\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"lapa.exam_languages.sv-FI",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":133},"end":{"line":5,"column":167}}}))
    + "</span></label>\n</div>\n\n<h2 data-i18n=\"sa.settings.update_username.change_email_address\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.settings.update_username.change_email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":65},"end":{"line":9,"column":2}}}))
    + "</h2>\n\n<div class=\"form-group\">\n  <label for=\"new-username\" data-i18n=\"sa.settings.update_username.new_email_address\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.settings.update_username.new_email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":4},"end":{"line":13,"column":58}}}))
    + "\n  </label>\n  <input type=\"email\" id=\"new-username\" class=\"text-input input-md\" name=\"username\" autocomplete=\"off\" maxlength=\"254\" />\n  <div class=\"validation-error\" data-validation-error-for=\"username\"></div>\n</div>\n\n<div class=\"form-group\">\n  <label for=\"password\" data-i18n=\"sa.password\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":24}}}))
    + "\n  </label>\n  <input type=\"password\" id=\"password\" class=\"input-md\" name=\"password\" autocomplete=\"current-password\">\n  <div class=\"validation-error\" data-validation-error-for=\"password\"></div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"done") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "\n<button class=\"button button--big\" id=\"submit-button\" type=\"submit\" data-i18n=\"sa.settings.update_username.change_email_address\"\n        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"done") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":8},"end":{"line":34,"column":35}}})) != null ? stack1 : "")
    + ">\n  "
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.settings.update_username.change_email_address",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":35,"column":2},"end":{"line":35,"column":60}}}))
    + "\n</button>\n";
},"useData":true});