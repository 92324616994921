var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"instructions-wrapper\">\n    <ul class=\"instructions-list\">\n        <li>\n            <a href=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":21},"end":{"line":4,"column":63}}}))
    + "\" target=\"_blank\"\n               data-i18n=\"sa.exam_execution_instructions_title\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_title",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":64},"end":{"line":5,"column":108}}}))
    + "</a>\n            <span data-i18n=\"sa.exam_execution_instructions\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":61},"end":{"line":6,"column":99}}}))
    + "</span>\n        </li>\n        <li data-i18n=\"[html]sa.exam_execution_instructions_help\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.exam_execution_instructions_help","",depth0,{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":66},"end":{"line":8,"column":117}}}))
    + "</li>\n    </ul>\n</div>\n";
},"useData":true});