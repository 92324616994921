var Handlebars = require("../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.bertta_instruction_url",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":9},"end":{"line":1,"column":42}}}))
    + "\" target=\"_blank\" data-i18n=\"sa.bertta_instruction_label\">\n    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"sa.bertta_instruction_label",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":39}}}))
    + "\n</a>";
},"useData":true});