import { Configuration, ContactInformationSchema } from './types'

const contactInformation = {
  titleKey: 'footer.contact_details',
  footerEmail: 'abitti@ylioppilastutkinto.fi',
  footerPhone: '+358 295 338 280',
  footerLinks: [
    {
      url: 'https://ylioppilastutkinto.fi/',
      titleKey: 'footer.meb'
    },
    {
      url: 'https://www.abitti.fi/fi/paivitykset/',
      titleKey: 'footer.updates'
    },
    {
      url: 'https://www.abitti.fi/fi/kayttoehdot/',
      titleKey: 'footer.digabi_terms'
    }
  ]
}

const configuration: Configuration = {
  contactInformation: ContactInformationSchema.parse(contactInformation)
}

export default configuration
